import { twitter, facebook, envelope, tiktok, whatsapp } from "../Common/Icons";
import { instagram } from "../Common/Icons";

export const HomeRedes = () => {
  return (
    <div className="home-redes">
      <div className="home-redes_container">
        <div className="home-redes_item">
          <a
            href="https://www.instagram.com/vassarferia/"
            target="_blank"
            rel="noreferrer"
          >
            {instagram}
          </a>
        </div>
        <div className="home-redes_item">
          <a
            href="https://twitter.com/vassarferia?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
            target="_blank"
            rel="noreferrer"
            without
          >
            {twitter}
          </a>
        </div>
        <div className="home-redes_item">
          <a
            href="https://www.tiktok.com/@vassarferia"
            target="_blank"
            rel="noreferrer"
            without
          >
            {tiktok}
          </a>
        </div>
        {/* 
        <div className="home-redes_item">
          <a href="!#" target="_blank" rel="noreferrer" without>
            {facebook}
          </a>
        </div>
        <div className="home-redes_item">
          <a href="!#" target="_blank" rel="noreferrer" without>
            {whatsapp}
          </a>
        </div> */}
      </div>
    </div>
  );
};
