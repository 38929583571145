export const rightArrow = (
  <svg
    width="68"
    height="13"
    viewBox="0 0 68 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path className="arrowLine" d="M35 8.03004H68" stroke="#241F20" />
    <path d="M46.6964 0L68 7.69402V8.44118L46.6964 16.06" stroke="#241F20" />
  </svg>
);

export const rightArrowLong = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="101.706"
    height="13"
    viewBox="0 0 101.706 16.998"
  >
    <g id="flecha_larga_final" transform="translate(70.683 0.469)">
      <path
        id="Path_533"
        data-name="Path 533"
        d="M94.489,0H194.647"
        transform="translate(-159.172 8.029)"
        fill="none"
        stroke="#BBB3A1"
        strokeWidth="1"
      />
      <path
        id="Path_87"
        data-name="Path 87"
        d="M-128.368,2066.473l21.029,7.693v.747l-21.029,7.618"
        transform="translate(143.862 -2066.473)"
        fill="none"
        stroke="#BBB3A1"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const rightArrowEmpre = (
  <svg
    width="100"
    height="100"
    viewBox="0 0 68 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path className="arrowLine" d="M35 8.03004H68" stroke="#241F20" />
    <path d="M46.6964 0L68 7.69402V8.44118L46.6964 16.06" stroke="#241F20" />
  </svg>
);

export const chevronUp = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.226 26.257">
    <g data-name="flecha hover">
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        d="M25.547 25.936 13.635.75h-1.156L.679 25.936"
        data-name="Path 88"
      />
    </g>
  </svg>
);

export const asteirsks = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.593 72.249">
    <g fill="#241F20" data-name="Group 122">
      <path
        d="M20.281 40.56.001 28.972l10.141-17.565 17.745 10.321V0h19.737v21.728l17.564-10.321 10.14 17.565L55.411 40.56l15.573 18.651-15.03 13.037-18.107-21.367-18.112 21.367L4.528 59.207Z"
        data-name="Path 273"
      />
      <path
        d="M109.547 40.56 89.266 28.972l10.141-17.565 17.746 10.321V0h19.737v21.728l17.563-10.321 10.14 17.565-19.918 11.588 15.572 18.651-15.028 13.037-18.108-21.367-18.106 21.367L93.79 59.207Z"
        data-name="Path 274"
      />
    </g>
  </svg>
);

export const plus = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.7 22.079">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M10.73 0v22.079" data-name="Line 7" />
      <path d="M21.7 10.969H0" data-name="Line 8" />
    </g>
  </svg>
);

export const twitter = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.455 31.253">
    <path
      fill="#241F20"
      d="M12.093 31.253c14.512 0 22.448-12.023 22.448-22.448 0-.342-.007-.682-.023-1.02a16.02 16.02 0 0 0 3.939-4.083 15.8 15.8 0 0 1-4.531 1.243A7.919 7.919 0 0 0 37.395.58a15.8 15.8 0 0 1-5.008 1.915 7.885 7.885 0 0 0-5.76-2.493 7.891 7.891 0 0 0-7.89 7.889 7.88 7.88 0 0 0 .2 1.8A22.4 22.4 0 0 1 2.675 1.448a7.872 7.872 0 0 0-1.069 3.965 7.881 7.881 0 0 0 3.511 6.566 7.82 7.82 0 0 1-3.573-.987v.1a7.894 7.894 0 0 0 6.33 7.735 7.9 7.9 0 0 1-2.079.277 7.848 7.848 0 0 1-1.485-.143 7.9 7.9 0 0 0 7.37 5.48 15.829 15.829 0 0 1-9.8 3.378 16.145 16.145 0 0 1-1.881-.109 22.336 22.336 0 0 0 12.094 3.544"
    />
  </svg>
);

export const tiktok = (
<svg xmlns="http://www.w3.org/2000/svg" width="56" height="65" viewBox="0 0 56 65" fill="none">
  <path d="M29.41 0.053527C32.928 0 36.4235 0.0267635 39.9149 0C40.0226 4.22542 41.6983 8.2564 44.611 11.2962C47.7629 14.1509 51.7728 15.8579 56 16.1446V27.0558C52.1013 26.9607 48.2625 26.0669 44.7172 24.4289C43.2052 23.7009 41.75 22.8585 40.3641 21.909C40.3376 29.8186 40.3907 37.7283 40.311 45.6111C40.1061 49.4386 38.8507 53.1335 36.6849 56.2836C34.9003 58.8871 32.5334 61.0308 29.7753 62.5414C27.0173 64.0521 23.9454 64.8873 20.808 64.9797C16.9653 65.1556 13.1562 64.1868 9.85598 62.1942C7.13972 60.5663 4.83878 58.3203 3.13707 55.6357C1.43535 52.9511 0.38004 49.9024 0.0554794 46.7332C0.0023934 45.3785 -0.0241496 44.0239 0.0289364 42.698C0.302154 39.9204 1.13804 37.2288 2.48482 34.7901C3.8316 32.3514 5.6606 30.2174 7.85855 28.5204C10.0565 26.8233 12.5766 25.5994 15.2627 24.9243C17.9488 24.2492 20.7438 24.1373 23.4746 24.5956C23.5277 28.604 23.3664 32.6144 23.3664 36.6227C22.2201 36.2173 21.0056 36.0443 19.7927 36.1137C18.5799 36.183 17.3926 36.4934 16.2991 37.027C15.2057 37.5606 14.2276 38.3068 13.4211 39.2228C12.6146 40.1388 11.9956 41.2065 11.5997 42.3645C11.178 43.7792 11.0501 45.2664 11.224 46.7332C11.5735 48.9658 12.7244 50.9912 14.4578 52.4243C16.1913 53.8575 18.3865 54.5987 20.6263 54.5069C22.1288 54.4625 23.5966 54.0405 24.8962 53.279C26.1959 52.5175 27.2864 51.4408 28.0686 50.1465C28.6672 49.2967 29.0455 48.3095 29.1691 47.2746C29.4386 42.4263 29.3304 37.5986 29.3569 32.7564C29.3835 21.8452 29.3304 10.9504 29.41 0.0617619V0.053527Z" fill="#231F20"/>
</svg>
);

export const facebook = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.046 38.603">
    <path
      fill="#241F20"
      d="M13.011 38.603V20.995h5.91l.886-6.863h-6.8V9.75c0-1.986.551-3.34 3.4-3.34h3.634V.273a48.659 48.659 0 0 0-5.295-.27c-5.24 0-8.827 3.2-8.827 9.071v5.061H0v6.863h5.924v17.607Z"
    />
  </svg>
);

export const envelope = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.53 62.268">
    <path
      fill="#241F20"
      d="M86.039 0H5.499l40.27 30.129Z"
      data-name="Path 288"
    />
    <path
      fill="#241F20"
      d="M3.668 62.239h84.073a3.131 3.131 0 0 0 .452.033 3.331 3.331 0 0 0 3.337-3.333l-.018-.073V4.85a7.042 7.042 0 0 0-.092-.805l-.7.513-42.206 31.681a3.915 3.915 0 0 1-5.491 0L14.907 15.155.007 3.971V58.72a3.2 3.2 0 0 0 .013.577 3.31 3.31 0 0 0 3.648 2.942Z"
      data-name="Path 289"
    />
  </svg>
);

export const instagram = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.455 38.233">
    <g fill="#241F20">
      <path
        d="M37.511 5.365A7.748 7.748 0 0 0 33.059.94a13.026 13.026 0 0 0-4.354-.8C26.231.028 25.49.003 19.228.003s-7 .025-9.478.137a13.032 13.032 0 0 0-4.355.8A7.748 7.748 0 0 0 .944 5.365a12.862 12.862 0 0 0-.807 4.329C.024 12.153 0 12.894 0 19.117s.024 6.963.137 9.422a12.875 12.875 0 0 0 .807 4.33 7.751 7.751 0 0 0 4.451 4.425 13.024 13.024 0 0 0 4.355.8c2.473.112 3.214.136 9.478.136s7-.025 9.477-.136a13.018 13.018 0 0 0 4.354-.8 7.751 7.751 0 0 0 4.452-4.425 12.871 12.871 0 0 0 .807-4.33c.113-2.459.137-3.2.137-9.422s-.024-6.964-.137-9.423a12.858 12.858 0 0 0-.807-4.329ZM19.228 31.091a11.974 11.974 0 1 1 12.043-11.974 12.009 12.009 0 0 1-12.043 11.974ZM31.742 9.468a2.8 2.8 0 1 1 2.814-2.8 2.806 2.806 0 0 1-2.814 2.8Z"
        data-name="Path 290"
      />
      <path
        d="M19.228 11.344a7.773 7.773 0 1 0 7.817 7.773 7.8 7.8 0 0 0-7.817-7.773Z"
        data-name="Path 291"
      />
    </g>
  </svg>
);

export const whatsapp = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.455 38.457">
    <path
      fill="#241F20"
      d="M32.829 5.637A19.1 19.1 0 0 0 19.236 0 19.253 19.253 0 0 0 0 19.219a19.169 19.169 0 0 0 2.941 10.23l.456.727-1.942 7.093 7.277-1.909.7.418a19.2 19.2 0 0 0 9.785 2.679h.009a19.251 19.251 0 0 0 19.227-19.222 19.1 19.1 0 0 0-5.624-13.598Zm-2.284 21.846c-.482 1.349-2.791 2.583-3.9 2.748a7.933 7.933 0 0 1-3.641-.229 33.2 33.2 0 0 1-3.294-1.218c-5.8-2.5-9.585-8.339-9.874-8.725s-2.36-3.132-2.36-5.978a6.476 6.476 0 0 1 2.022-4.819 2.126 2.126 0 0 1 1.542-.724c.385 0 .771 0 1.108.02.354.017.831-.136 1.3.991.483 1.158 1.638 4 1.782 4.29a1.069 1.069 0 0 1 .049 1.014 3.958 3.958 0 0 1-.578.963c-.289.339-.608.755-.868 1.013-.29.288-.591.6-.252 1.179a17.388 17.388 0 0 0 3.214 4 15.811 15.811 0 0 0 4.648 2.867c.578.29.916.241 1.252-.144s1.445-1.687 1.831-2.265.769-.483 1.3-.291 3.37 1.591 3.949 1.881.964.434 1.108.675a4.823 4.823 0 0 1-.338 2.752Z"
    />
  </svg>
);

export const cross = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.7 22.079">
    <g fill="none" stroke="currentColor">
      <path d="M10.73 0v22.079" data-name="Line 7" />
      <path d="M21.7 10.969H0" data-name="Line 8" />
    </g>
  </svg>
);

export const arrowDown = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.131 10.974">
    <g data-name="flecha hover">
      <path
        fill="none"
        stroke="#241F20"
        strokeWidth="1.5"
        d="m.678.32 4.682 9.9h.455l4.637-9.9"
        data-name="Path 88"
      />
    </g>
  </svg>
);

export const mainLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.196 44.315">
    <path
      fill="currentColor"
      d="M14.814 30.514 23.55 0h3.672L14.181 44.315h-1.266L0-.001h6.2Z"
      data-name="Path 118"
    />
    <path
      fill="currentColor"
      d="M33.356-.001h3.8l13.041 44.315h-6.2l-2.469-8.99H29.051l-2.405 8.99h-6.2Zm1.836 13.675-5.508 19.309h11.205Z"
      data-name="Path 119"
    />
  </svg>
);

export const lupa = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.692"
    height="23.837"
    viewBox="0 0 24.692 23.837"
  >
    <path
      id="lupa_bucador_galería"
      d="M28.326,26.871l-5.5-5.5a10.251,10.251,0,0,0,2.269-6.31,10.192,10.192,0,0,0-6.6-9.439A10.056,10.056,0,0,0,6.474,20.3a10.213,10.213,0,0,0,10.483,4.642A9.968,9.968,0,0,0,22.093,22.2l5.45,5.45C28.039,28.123,28.821,27.367,28.326,26.871ZM18.078,23.508A8.973,8.973,0,0,1,7.335,10.5a9.041,9.041,0,0,1,9.492-4.224,9.11,9.11,0,0,1,7.2,8.788A9.146,9.146,0,0,1,18.078,23.508Z"
      transform="translate(-4.292 -4.514)"
      fill="#fff"
      stroke="#8A1A9B"
      strokeWidth="1"
    />
  </svg>
);

export const equisClose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50.634"
    height="50.634"
    viewBox="0 0 50.634 50.634"
  >
    <g id="equis_cerrar_boton_va_web_hover" transform="translate(0.707 0.707)">
      <line
        id="Line_12"
        data-name="Line 12"
        y2="69.607"
        transform="translate(49.22 0) rotate(45)"
        fill="none"
        stroke="#241F20"
        strokeWidth="2"
      />
      <line
        id="Line_13"
        data-name="Line 13"
        y1="69.607"
        transform="translate(49.22 49.22) rotate(135)"
        fill="none"
        stroke="#241F20"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const estrellasNewsletter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="160"
    height="207.606"
    viewBox="0 0 160 207.606"
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="Rectángulo_479"
          data-name="Rectángulo 479"
          width="128.2"
          height="126.282"
          fill="none"
        />
      </clipPath>
      <clipPath id="clipPath-2">
        <rect
          id="Rectángulo_479-2"
          data-name="Rectángulo 479"
          width="95.9"
          height="94.465"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="Grupo_1060"
      data-name="Grupo 1060"
      transform="translate(-601.85 -5553.859)"
    >
      <g
        id="Grupo_671"
        data-name="Grupo 671"
        transform="translate(601.85 5553.859)"
      >
        <g id="Grupo_670" data-name="Grupo 670" clipPath="url(#clipPath)">
          <path
            id="Trazado_1670"
            data-name="Trazado 1670"
            d="M64.1,126.282c0-34.872,28.7-63.142,64.1-63.142C92.8,63.14,64.1,34.872,64.1,0,64.1,34.872,35.4,63.14,0,63.14c35.4,0,64.1,28.269,64.1,63.142"
            transform="translate(0.001)"
            fill="#241F20"
          />
        </g>
      </g>
      <g
        id="Grupo_672"
        data-name="Grupo 672"
        transform="translate(665.95 5667)"
      >
        <g id="Grupo_670-2" data-name="Grupo 670" clipPath="url(#clipPath-2)">
          <path
            id="Trazado_1670-2"
            data-name="Trazado 1670"
            d="M47.949,94.465c0-26.086,21.466-47.233,47.95-47.233-26.484,0-47.95-21.146-47.95-47.232,0,26.086-21.467,47.232-47.95,47.232,26.483,0,47.95,21.147,47.95,47.233"
            transform="translate(0.001)"
            fill="#241F20"
          />
        </g>
      </g>
    </g>
  </svg>
);
