import image1 from '../../assets/compras_exp.jpg'

export const fundacionesItems = [
    {
        title: 'Logo',
        image: image1,
        className: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut mauris lectus. In sodales tortor quis dui mollis euismod. Sed in semper enim. Pellentesque id efficitur nunc, a accumsan nunc. Ut laoreet feugiat orci at facilisis. Cras at cursus metus, sed feugiat arcu. Aliquam ut purus non nunc ullamcorper suscipit. Nulla consequat diam nulla, sit amet posuere nisi malesuada at. Quisque augue risus, malesuada vel pharetra in, euismod vel erat. Sed purus risus, molestie vel fringilla at, volutpat a ipsum. Nunc ac varius ex. Nulla lectus ipsum, consectetur quis massa nec, porta pretium odio. Vivamus ut velit ut arcu scelerisque.',
        buttons: [
            {
                content: 'Ir a página <strong>web</strong>',
                className: '',
            },
        ]
    },
    {
        title: 'Logo',
        image: image1,
        className: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut mauris lectus. In sodales tortor quis dui mollis euismod. Sed in semper enim. Pellentesque id efficitur nunc, a accumsan nunc. Ut laoreet feugiat orci at facilisis. Cras at cursus metus, sed feugiat arcu. Aliquam ut purus non nunc ullamcorper suscipit. Nulla consequat diam nulla, sit amet posuere nisi malesuada at. Quisque augue risus, malesuada vel pharetra in, euismod vel erat. Sed purus risus, molestie vel fringilla at, volutpat a ipsum. Nunc ac varius ex. Nulla lectus ipsum, consectetur quis massa nec, porta pretium odio. Vivamus ut velit ut arcu scelerisque.',
        buttons: [
            {
                content: 'Ir a página <strong>web</strong>',
                className: '',
            },
        ]
    },
];