import { useEffect, useState } from "react";
import { getPosts } from "../lib/api";
import { Posts } from "./Posts";

export const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts().then((data) => setPosts(data.data));
  }, []);

  return (
    <div className="blog">
      <div className="blog_title">
        <h2>
          Blo
          <span className="libre_font">g</span>
        </h2>
      </div>

      <div className="blog_container">
        <Posts posts={posts} />
      </div>
    </div>
  );
};
