import { plus } from "../Common/Icons";
import { Link } from "react-router-dom";
import sello from '../../assets/2023/sello.png';

export const HomeExperience = ({ experiencia }) => {
  return (
    <div className="home-experience">
      <div className="home-experience_title">
        <h2>Experiencia</h2>
        <p>Descubre los espacios y experiencias que nos hacen diferentes.</p>

        <img className="sello" src={sello} alt="sello" />
      </div>

      <div className="home-experience_container">
        <div class="grid-gallery">
          {experiencia.map((item, i) => (
            <div class="grid-gallery__item">
              <img class="grid-gallery__image" src={item.imagen.url} />
            </div>
          ))}
        </div>

        <Link to="/experiencia">
          <button>
            <span>Ver más</span>
            <div className="">{plus}</div>
          </button>
        </Link>
      </div>
    </div>
  );
};
