export const ROUTES = {
    HOME: "/",
    HOMECONTACTO: "/contacto",
    QUESTIONS: "/questions",
    STARTUPS: "/emprendimientos/",
    STARTUPSSEARCH: "/emprendimientos/:search",
    STARTUPSLISTA: "/emprendimientos-lista",
    STARTUPFILTER: "/emprendimientos/filter/:filtro",
    SINGLE:"/emprendimiento/:id",
    FAQ: "/faq",
    EXPERIENCE: "/experiencia",
    VASSAR: "/vassar",
    PATROCINADORES: "/patrocinadores",
    FUNDACIONES: "/fundaciones",
    GALERIA: "/galeria",
    BLOG: "/blog",
    POST: "/blog/post/:id",

    CMS_LOGIN: "/login",
    CMS_EMPRENDIMIENTO: "/profile",
    CMS_LOGOUT: "/logout",

    CMS_ADMIN: "/admin-login",
    CMS_LISTA: "/admin-lista"
};