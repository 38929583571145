import { NewsletterForm } from "./NewsletterForm";
import imgparamodal from "../../../assets/imgparamodal.png";

export const NewsletterModal = ({ toggleModal }) => {
  return (
    <div className="newsletter_modal">
      {window.innerWidth < 999 ? (
        <div className="titulo_movil max-width">
          <h1>
            <strong>News</strong>
            <span className="libre_font">letter</span>
          </h1>
          <p>
            Descubre más de la fuerza creadora de Vassar y se parte de este
            impulso emprendedor, inscríbete aquí y recibe noticias,
            publicaciones y sorpresas que esta comunidad trae para tí.
          </p>
        </div>
      ) : (
        <div className="imagen">
          <img src={imgparamodal} alt="imgparamodal" />
        </div>
      )}

      <NewsletterForm toggleModal={toggleModal} />
    </div>
  );
};
