export const categorias = [
    {
        id: 1,
        value: 'mascotas',
        label: 'Mascotas',
    },
    {
        id: 2,
        value: 'hogar',
        label: 'Hogar y decoración',
    },
    {
        id: 3,
        value: 'accesorios',
        label: 'Accesorios',
    },
    {
        id: 4,
        value: 'restaurante',
        label: 'Restaurante',
    },
    {
        id: 5,
        value: 'kids',
        label: 'Kids',
    },
    {
        id: 6,
        value: 'salud',
        label: 'Salud y belleza',
    },
    {
        id: 7,
        value: 'mercado',
        label: 'Mercado gastronómico',
    },
    {
        id: 8,
        value: 'moda',
        label: 'Moda',
    },
    {
        id: 9,
        value: 'arte',
        label: 'Arte y diseño',
    },
    {
        id: 10,
        value: 'fundaciones',
        label: 'Fundaciones',
    }
]